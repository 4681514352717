#main{
    width: 100%;
    min-height: calc(98vh - 9rem);
    /* padding-left: 1vw; */
    padding-top: 1vh;
    /* background-color: aqua; */

    /* padding-top: 2vh; */
    /* padding: 20px; */
    
}
#footer {
    /* px: "$12";
    mt: "$8";
    xsMax: { px: "$10" }; */
    /* width:100%; */
    /* background-color: #00FFF0; */
    min-height: 5rem;
    text-align: center;
    padding-top: 2vh;
    padding-bottom: 1vh;
    /* position: absolute; */
    bottom: 0;
}

.footer_text {
    /* background-color: #00FFF0;  */
    display: flex; 
    justify-content: center;
    margin: auto;

}