.icon_qq {
    font-size:10rem;

  }
.md-editor {
    min-height: 80vh;
}
.md-editor-content {
    /*   必须, 代码内左右需要移动 */
    /* position:absolute; */
    /* min-height: 1000px; */
    /* display: block; */
}
.md-editor-preview-wrapper {
    position:absolute;
    /* min-height: 100px; */
}
.md-editor-preview{
    position:absolute;
    /* min-height: 10px; */

}
.md-editor-catalog {
    /* width: 100px; */
    /* font-size: large; */
    /* text-overflow:"ellipsis"; */
}

.md-editor-catalog-link {
    width: 16vw;
    text-overflow:"-";  
    display:block;
    white-space:pre-wrap;
    word-wrap : break-word ;
}
#homeview{
    /* background-color:bisque; */
}
#pageination{
    text-align: center;
    margin-top: 2vh;
    
}

#labels {
    display: flex;
    
}
#account {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
#main{
    width: 100%;
    min-height: calc(98vh - 9rem);
    /* padding-left: 1vw; */
    padding-top: 1vh;
    /* background-color: aqua; */

    /* padding-top: 2vh; */
    /* padding: 20px; */
    
}
#footer {
    /* px: "$12";
    mt: "$8";
    xsMax: { px: "$10" }; */
    /* width:100%; */
    /* background-color: #00FFF0; */
    min-height: 5rem;
    text-align: center;
    padding-top: 2vh;
    padding-bottom: 1vh;
    /* position: absolute; */
    bottom: 0;
}

.footer_text {
    /* background-color: #00FFF0;  */
    display: flex; 
    justify-content: center;
    margin: auto;

}
.app {
    background-color: #E1EFFF;
}
/* .nextui-c-eFfoBo {
    height: 100vh;
} */

.md-editor {
    min-height: 80vh;
}
body, html {
    overflow-x: hidden;
} 
