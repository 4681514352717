#homeview{
    /* background-color:bisque; */
}
#pageination{
    text-align: center;
    margin-top: 2vh;
    
}

#labels {
    display: flex;
    
}
#account {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}