.md-editor {
    min-height: 80vh;
}
.md-editor-content {
    /*   必须, 代码内左右需要移动 */
    /* position:absolute; */
    /* min-height: 1000px; */
    /* display: block; */
}
.md-editor-preview-wrapper {
    position:absolute;
    /* min-height: 100px; */
}
.md-editor-preview{
    position:absolute;
    /* min-height: 10px; */

}
.md-editor-catalog {
    /* width: 100px; */
    /* font-size: large; */
    /* text-overflow:"ellipsis"; */
}

.md-editor-catalog-link {
    width: 16vw;
    text-overflow:"-";  
    display:block;
    white-space:pre-wrap;
    word-wrap : break-word ;
}